<template>
  <div class="signIn app-contianer">
    <PageBg bgImg />

    <div id="app-content">
      <!-- 微信登录 -->
      <template v-if="loginType === 'weChat'">
        <div class="title-box">
          <div class="item"><strong>欢迎登录</strong></div>
          <div class="item name"
            ><strong>{{ agentConfig.bwcName }}</strong></div
          >
          <div class="line mainBgColor"></div>
        </div>
        <div class="submitBut f40 weChat" @click="wechatLogin">
          <span class="page-icon2 wechatBut vertical-middle"></span>
          <span class="vertical-middle margin-lr2">微信登录</span>
        </div>
        <!-- <div class="tac footer wechat">
          <van-divider>其他登录方式</van-divider>
          <div @click="changeLogin('phone')">
            <div class="page-icon2 phone"></div>
            <div class="f34 margin-t1 grey6">手机登录</div>
          </div>
        </div> -->
      </template>

      <!-- 手机号/密码登录 -->
      <template v-if="loginType === 'phone' || loginType === 'password'">
        <div class="title-box">
          <div class="item"><strong>欢迎登录</strong></div>
          <div class="item name"
            ><strong>{{ agentConfig.bwcName }}</strong></div
          >
          <div class="line mainBgColor"></div>
        </div>
        <form class="phone-form">
          <div class="item">
            <input
              type="text"
              placeholder="请输入手机号码"
              v-model="form.phone"
              maxlength="11"
              onkeyup="value=value.replace(/[^\d]/g,'')"
            />
          </div>
          <div v-if="loginType === 'phone'">
            <div class="item">
              <input type="text" placeholder="请输入图形验证码" v-model="form.captcha" maxlength="4" />
              <div class="captcha" v-if="captchaImg !== ''" @click="getCapcha">
                <img :src="captchaImg" />
              </div>
            </div>
            <div class="item">
              <input
                type="text"
                placeholder="请输入验证码"
                v-model="form.code"
                autocomplete="one-time-code"
                onkeyup="value=value.replace(/[^\d]/g,'')"
                maxlength="6"
              />
              <div class="mainColor">
                <span v-if="num > 0 && num !== 120">{{ num }}秒后可重新获取</span>
                <span v-else @click="getCode">获取验证码</span>
              </div>
            </div>
          </div>
          <div v-if="loginType === 'password'">
            <div class="item">
              <input type="password" placeholder="请输入密码" v-model="form.password" maxlength="20" />
            </div>
          </div>
        </form>
        <div class="submitBut" @click="onSubmit()">登录</div>
        <div class="footer phone tac">
          <div v-if="canShowWeichat">
            <!-- <div> -->
            <van-divider>其他登录方式</van-divider>
            <div @click="changeLogin('weChat')">
              <div class="page-icon2 wechat"></div>
              <div class="f34 margin-t1 grey6">微信登录</div>
            </div>
          </div>
          <div v-if="isApp">
            <van-divider>其他登录方式</van-divider>
            <!-- <div v-if="agentid === 'A10017309148'" class="submitBut wechatLogin" @click="wechatLogin">微信登录</div> -->
            <div v-if="loginType === 'password'" class="submitBut" @click="changeLogin('phone')">手机验证码登录</div>
            <div v-if="loginType === 'phone'" class="submitBut" @click="changeLogin('password')">账号密码登录</div>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import { Toast } from 'vant'
import { mapState } from 'vuex'
import { mallApi } from '@/api'
import { judgeTerrace, getUrlParams } from '@/utils/utils'
import PageBg from '@/components/PageBg.vue'
import base64 from '@/utils/base64'
const agentCustomize = require('@/utils/agentCustomize')

export default {
  name: 'signIn',
  components: { PageBg },
  data() {
    return {
      loading: false,
      logoImg: agentCustomize.LoginLogoImg,
      form: {
        phone: '',
        code: '',
        captcha: ''
      },
      num: 120,
      uuid: '',
      captchaImg: '',
      agentid: localStorage.getItem('agent'),
      loginType: 'weChat'
    }
  },
  computed: {
    ...mapState('user', ['agentConfig', 'isApp', 'location', 'cityName', 'address', 'appConfigJson']),
    canShowWeichat() {
      return this.agentConfig.MP_APPID !== 'NON' && judgeTerrace()
    }
  },
  watch: {
    loginType(val) {
      if (val === 'phone') {
        this.getCapcha()
      }
    }
  },
  created() {
    // app兼容
    window.pageVueObj = this
    if (this.isApp || !judgeTerrace()) {
      this.loginType = 'phone'
    }
    // 回调地址
    const urlParams = getUrlParams(location.hash)
    this.redirect = unescape(urlParams.redirect) || ''
  },
  destroyed() {
    window.pageVueObj = null
  },
  methods: {
    //获取验证码图片
    getCapcha() {
      if (!this.agentid) {
        this.$toast('网页地址错误, 请输入正确地址')
        return
      }

      this.form.captcha = ''
      mallApi({
        trxCode: 'XF168',
        agentId: this.agentid
      }).then((res) => {
        this.uuid = res.uuid
        this.captchaImg = res.img
      })
    },

    // 图形验证码校验
    getCode() {
      if (this.form.phone === '') {
        this.$toast('请输入电话号码')
        return
      }

      let reg = /^[1][3,4,5,6,7,8,9][0-9]{9}$/
      if (!reg.test(this.form.phone)) {
        this.$toast('请输入正确的手机号码')
        return
      }

      mallApi({
        trxCode: 'XF169',
        agentId: this.agentid,
        uuid: this.uuid,
        captcha: this.form.captcha
      }).then((res) => {
        if (res.trxCode) {
          this.getCodeing() //图形过关发送验证码
        } else {
          this.getCapcha()
          this.$toast('图形验证码：' + res.rspMsg)
        }
      })
    },

    //发送验证码
    getCodeing() {
      mallApi({
        trxCode: 'XF172',
        phone: this.form.phone,
        agentId: this.agentid
      }).then((res) => {
        if (res.trxCode) {
          localStorage.setItem('time', Date.parse(new Date()) / 1000)
          this.$toast('已发送')
          this.getTime()
        } else {
          this.$toast(res.rspMsg)
        }
      })
    },
    getTime() {
      var date2 = Date.parse(new Date()) / 1000
      var time = date2 - localStorage.getItem('time')
      this.num = 120 - time
      if (this.num > 0) {
        setTimeout(this.getTime, 1000)
      }
    },

    // 手机号码登录
    onSubmit() {
      if (this.loading) return
      if (this.form.captcha === '' && this.loginType === 'phone') {
        this.$toast('请填写完整的信息')
        return
      }

      if (this.form.password === '' && this.loginType === 'password') {
        this.$toast('请填写完整的信息')
        return
      }

      let params = {
        trxCode: 'XF170',
        agentId: this.agentid,
        phone: this.form.phone
      }

      if (this.loginType === 'phone') {
        params.code = this.form.code
      }

      if (this.loginType === 'password') {
        params.type = '04'
        const Base64 = new base64()
        params.password = Base64.encode(this.form.password)
      }

      let parentId = sessionStorage.getItem('parentId')
      if (parentId) {
        params.type = '00'
        params.parentId = parentId
      }

      let promoterId = sessionStorage.getItem('promoterId')
      if (promoterId) {
        params.type = '03'
        params.parentId = promoterId
      }

      this.loading = true
      mallApi(params).then((res) => {
        if (res.trxCode) {
          if (parentId) {
            // this.$toast('登陆成功, 推广id: ' + parentId)
            sessionStorage.removeItem('parentId')
          } else {
            this.$toast('登陆成功')
          }
          this.$store.commit('user/SET_CUSTMSG', res.custMsg)
          localStorage.setItem('H5token', res.APPLETTOKEN)

          // 设置定位信息
          // const custMsg = res.custMsg
          // if (custMsg.latitude && custMsg.longitude && custMsg.cityName && custMsg.address) {
          //   let location = {}
          //   location.longitude = custMsg.longitude
          //   location.latitude = custMsg.latitude
          //   this.$store.commit('user/SET_LOCATION', location)
          //   this.$store.commit('user/SET_CITYNAME', custMsg.cityName)
          //   this.$store.commit('user/SET_ADDRESS', custMsg.address)
          // }

          if (!res.custMsg.cityName) {
            mallApi({
              trxCode: 'XF174',
              longitude: this.location.longitude,
              latitude: this.location.latitude,
              cityName: this.cityName,
              address: this.address,
              oldCityName: ''
            }).then((res) => {
              this.$store.commit('user/SET_CUSTMSG', res.custMsg)
            })
          }

          this.$router.replace(this.redirect)
        } else {
          this.getCapcha()
          this.$toast(res.rspMsg)
          this.loading = false
        }
      })
    },

    // 微信登录
    wechatLogin() {
      // 记录拉新用户ID
      let parentId = sessionStorage.getItem('parentId')
      if (parentId) {
        localStorage.setItem('parentId', parentId)
      }

      // 记录推广员用户ID
      let promoterId = sessionStorage.getItem('promoterId')
      if (promoterId) {
        localStorage.setItem('promoterId', promoterId)
      }

      // 记录回调地址
      if (this.redirect) {
        localStorage.setItem('redirectUrl', this.redirect)
      }

      // 回调地址
      const redirectUrl = `${this.agentConfig.SELF_DOMAIN_USER}`

      let url = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${this.agentConfig.MP_APPID}&redirect_uri=${redirectUrl}&response_type=code&scope=snsapi_userinfo&state=123&component_appid=wx30a1ec0b9bfbcf9b#wechat_redirect`

      // 其他域名
      let domainAgent = this.appConfigJson.domainAgentId.find((v) => v.domain === window.location.host)
      if (domainAgent) {
        url = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${this.agentConfig.MP_APPID}&redirect_uri=${redirectUrl}&response_type=code&scope=snsapi_userinfo&state=123#wechat_redirect`
      }
      console.log(url)

      if (this.isApp) {
        uni.postMessage({
          data: {
            type: 'wechatLogin'
          }
        })
      } else {
        window.location.href = url
      }
    },

    // 切换账号密码登录
    changeLogin(val) {
      if (val === 'password') {
        this.loginType = 'password'
        delete this.form.code
        delete this.form.captcha
        return
      }

      if (val === 'phone') {
        this.loginType = 'phone'
        delete this.form.password
        delete this.form.type
        return
      }

      if (val === 'weChat') {
        this.loginType = 'weChat'
      }
    }
  }
}
// app兼容-微信登录
window.testEvalJS = function (val) {
  Toast(JSON.stringify(val))
  window.pageVueObj.form.phone = 123
  const that = window.pageVueObj
  let params = {
    jsCode: val.code,
    appId: that.agentConfig.MP_APPID,
    agentId: localStorage.getItem('agent'),
    trxCode: 'XF108'
  }

  mallApi(params).then((res) => {
    if (res.APPLETTOKEN) {
      localStorage.setItem('H5token', res.APPLETTOKEN)
      that.$store.commit('user/SET_CUSTMSG', res.custMsg)

      // 获取定位信息
      const custMsg = res.custMsg
      if (custMsg.latitude && custMsg.longitude && custMsg.cityName && custMsg.address) {
        let location = {}
        location.longitude = custMsg.longitude
        location.latitude = custMsg.latitude
        that.$store.commit('user/SET_LOCATION', location)
        that.$store.commit('user/SET_CITYNAME', custMsg.cityName)
        that.$store.commit('user/SET_ADDRESS', custMsg.address)
      }
    } else {
      that.$toast(res.rspMsg)
    }
  })
}
</script>

<style lang="less" scoped>
#app-content {
  min-height: 100vh;
  padding: 0;
  position: relative;
}
.title-box {
  padding: 8vw 6vw;
  margin-top: 10vw;
  font-size: 5.4vw;

  .item {
    margin-bottom: 4vw;
  }
  .name {
    text-indent: 5vw;
  }
  .line {
    height: 4px;
    width: 16vw;
    border-radius: 1px;
    margin-top: 5vw;
  }
}

.phone-form {
  text-align: center;
  margin-top: 5vw;
  padding: 0 6vw;

  input {
    width: 100%;
    font-size: 4vw;
    color: #333;
    padding: 4vw;
  }

  .item {
    width: 100%;
    display: flex;
    align-items: center;
    margin-bottom: 5vw;
    background: #fff;
    border-radius: 10px;
    overflow: hidden;

    input {
      width: 60%;
    }

    div {
      width: 40%;
      font-size: 3vw;
    }

    .captcha {
      img {
        width: 100%;
      }
    }
  }
}

p {
  text-align: left;
  font-size: 4vw;
  color: #999999;
  margin-top: 3vw;
  margin-bottom: 1vw;
}

.phone-button {
  font-size: 4vw;
  color: #ffffff;
  margin-top: 26vw;
  margin-bottom: 10vw;
  padding: 0 4vw;

  .button {
    width: 48%;
    height: 100%;
    background: linear-gradient(-90deg, #9169f6 0%, #6497ff 100%);
    border-radius: 999vw;
    text-align: center;
    padding: 3vw 0;
    display: inline-block;
  }
}

.footer {
  width: 100%;

  .page-icon2.phone {
    width: 44px;
    height: 44px;
    background-position: -34px -72px;
  }
  .page-icon2.wechat {
    width: 44px;
    height: 44px;
    background-position: -91px -72px;
  }
}
.footer.wechat {
  margin-top: 32vh;
}
.footer.phone {
  margin-top: 18vh;
}
.submitBut {
  margin-left: 6vw;
  margin-right: 6vw;
}
.submitBut.weChat {
  margin-top: 12vw;
}
.wechatLogin {
  background: #1aad19;
}
.page-icon2.wechatBut {
  width: 24px;
  height: 20px;
  background-position: -78px -30px;
}
</style>
